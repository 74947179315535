<template>
  <div id="app">
    <!--<transition>-->
    <!--<router-view></router-view>-->
    <!--</transition>-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  mounted() {

  }
}
</script>
<style>
body {
  padding: 0px;
  margin: 0px;
  background: #f5f5f9
}

.van-grid-item__text {
  margin-top: 8px;
}
</style>
