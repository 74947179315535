import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  { path: '/login', name: 'login', component: () => import('@/views/login.vue'), meta: { notLogin: true } },
  { path: '/', name: 'default', component: () => import('@/views/default.vue') },
  { path: '/401', name: 'p401', component: () => import('@/views/p401.vue') },

  { path: '/org/teacher', name: 'org-teacher', component: () => import('@/views/org/teacher/mine.vue'), meta: { title: "个人中心" } },
  { path: '/org/teahonor', name: 'org-teahonor', component: () => import('@/views/org/teahonor/index.vue'), meta: { title: "教师荣誉" } },
  { path: '/org/teahonor/record', name: 'org-teahonor-record', component: () => import('@/views/org/teahonor/record.vue'), meta: { title: "荣誉记录" } },
  { path: '/org/teahonor/new', name: 'org-teahonor-new', component: () => import('@/views/org/teahonor/new.vue'), meta: { title: "新增荣誉" } },
  { path: '/org/teahonor/edit', name: 'org-teahonor-edit', component: () => import('@/views/org/teahonor/edit.vue'), meta: { title: "编辑荣誉" } },

  { path: '/fix', name: 'fix-index', component: () => import('@/views/fix/index.vue'), meta: { title: "园内维修" } },
  { path: '/fix/note/new', name: 'fix-note-new', component: () => import('@/views/fix/note/new.vue'), meta: { title: "新维修" } },
  { path: '/fix/note/list', name: 'fix-note-list', component: () => import('@/views/fix/note/list.vue'), meta: { title: "维修记录" } },
  { path: '/fix/note/pro', name: 'fix-note-pro', component: () => import('@/views/fix/note/pro.vue'), meta: { title: "待分配记录" } },
  { path: '/fix/note/report', name: 'fix-note-report', component: () => import('@/views/fix/note/report.vue'), meta: { title: "维修报告" } },
  { path: '/mat', name: 'mat-index', component: () => import('@/views/mat/index.vue'), meta: { title: "仓库管理" } },
  { path: '/mat/stock', name: 'mat-stock', component: () => import('@/views/mat/stock/index.vue'), meta: { title: "库存信息" } },
  { path: '/mat/stock/edit', name: 'mat-stock', component: () => import('@/views/mat/stock/edit.vue'), meta: { title: "编辑物品" } },
  { path: '/mat/stock/new', name: 'mat-stock-new', component: () => import('@/views/mat/stock/new.vue'), meta: { title: "新增物品" } },
  { path: '/mat/bill', name: 'mat-bill', component: () => import('@/views/mat/bill/index.vue'), meta: { title: "记录查询" } },
  { path: '/mat/bill/new', name: 'mat-bill-new', component: () => import('@/views/mat/bill/new.vue'), meta: { title: "新增出库" } },
  { path: '/mat/valid', name: 'mat-valid', component: () => import('@/views/mat/valid/index.vue'), meta: { title: "单据审批" } },
  { path: '/mat/valid/new', name: 'mat-valid-new', component: () => import('@/views/mat/valid/new.vue'), meta: { title: "新申请" } },
  { path: '/mat/valid/lst', name: 'mat-valid-lst', component: () => import('@/views/mat/valid/lst.vue'), meta: { title: "我的申请", keepAlive: true } },
  { path: '/mat/valid/edit', name: 'mat-valid-edit', component: () => import('@/views/mat/valid/edit.vue'), meta: { title: "记录详情" } },
  { path: '/mat/valid/conf', name: 'mat-valid-conf', component: () => import('@/views/mat/valid/conf.vue'), meta: { title: "配置流程" } },
  { path: '/mat/valid/edit2', name: 'mat-valid-edit2', component: () => import("@/views/mat/valid/edit2.vue"), meta: { title: '我的申请' } },

  { path: '/cap', name: 'cap-index', component: () => import('@/views/cap/index.vue'), meta: { title: "固定资产" } },
  { path: '/cap/scan', name: 'cap-scan', component: () => import('@/views/cap/info/scan.vue'), meta: { title: "固定资产" } },
  { path: '/cap/lst', name: 'cap-lst', component: () => import('@/views/cap/info/lst.vue'), meta: { title: "固定资产" } },
  { path: '/safe', name: 'safe-index', component: () => import('@/views/safe/index.vue'), meta: { title: "安全巡检" } },
  { path: '/safe/rec', name: 'safe-rec', component: () => import('@/views/safe/rec/index.vue'), meta: { title: "巡检记录", notLogin: true } },
  { path: '/safe/rec/scan', name: 'safe-rec-scan', component: () => import('@/views/safe/rec/scan.vue'), meta: { title: "巡检-扫码录入" } },
  { path: '/safe/rep', name: 'safe-rep', component: () => import('@/views/safe/rep/index.vue'), meta: { title: "巡检报告" } },
  { path: '/safe/rep/other', name: 'safe-rep-other', component: () => import('@/views/safe/rep/other.vue'), meta: { title: " 总巡检报告", notLogin: true } },
  { path: '/safe/type', name: 'safe-type', component: () => import('@/views/safe/type/index.vue'), meta: { title: '巡检', notLogin: true } },
  { path: '/safe/type2', name: 'safe-type2', component: () => import('@/views/safe/type/index2.vue'), meta: { title: '巡检', notLogin: true } },
  { path: '/safe/info', name: 'safe-info', component: () => import('@/views/safe/type/info.vue'), meta: { title: '巡检', notLogin: true } },

  { path: '/safe/abnormal', name: 'safe-abnormal', component: () => import('@/views/safe/abnormal/index.vue'), meta: { title: '异常处理' } },
  { path: '/safe/abInfo', name: 'safe-abInfo', component: () => import('@/views/safe/abnormal/info.vue'), meta: { title: '异常处理' } },
  { path: '/safe/cre', name: 'safe-cre', component: () => import('@/views/safe/abnormal/edit.vue'), meta: { title: '异常处理' } },
  { path: '/safe/record', name: 'safe-record', component: () => import('@/views/safe/abnormal/record.vue'), meta: { title: '全部记录' } },

  { path: '/wfl', name: 'wfl-index', component: () => import('@/views/wfl/index.vue'), meta: { title: "工作流转" } },
  { path: '/wfl/sub', name: 'wfl-sub', component: () => import('@/views/wfl/sub.vue'), meta: { title: "工作流转-我的申请" } },
  { path: '/wfl/pro', name: 'wfl-pro', component: () => import('@/views/wfl/pro.vue'), meta: { title: "工作流转-处理工作" } },
  { path: '/wfl/all', name: 'wfl-all', component: () => import('@/views/wfl/all.vue'), meta: { title: "工作流转-全部记录" } },
  { path: '/wfl/mat/new', name: 'wfl-mat-new', component: () => import('@/views/wfl/mat/new.vue'), meta: { title: "工作流转-物料申领" } },
  { path: '/wfl/mat/edit', name: 'wfl-mat-edit', component: () => import('@/views/wfl/mat/edit.vue'), meta: { title: "工作流转-处理物料申领" } },
  { path: '/wfl/mat/purchase/new', name: 'wfl-mat-purchase-new', component: () => import('@/views/wfl/mat/purchase/new.vue'), meta: { title: "工作流转-物资采购" } },
  { path: '/wfl/mat/purchase/edit', name: 'wfl-mat-purchase-edit', component: () => import('@/views/wfl/mat/purchase/edit.vue'), meta: { title: "工作流转-处理物资采购" } },
  { path: '/utl/rent', name: 'utl-rent', component: () => import('@/views/utl/rent/index.vue'), meta: { title: "物品借用" } },
  { path: '/utl/rent/lst', name: 'utl-rent-lst', component: () => import('@/views/utl/rent/lst.vue'), meta: { title: "借用记录" } },
  { path: '/utl/rent/new', name: 'utl-rent-new', component: () => import('@/views/utl/rent/new.vue'), meta: { title: "物品借用-登记" } },

  { path: '/meet', name: 'meet-index', component: () => import('@/views/meet/index.vue'), meta: { title: "会议记录" } },
  { path: '/meet/edit', name: 'meet-edit', component: () => import('@/views/meet/edit/index.vue'), meta: { title: "会议记录-近期" } },
  { path: '/meet/edit/info', name: 'meet-edit-info', component: () => import('@/views/meet/edit/info.vue'), meta: { title: "会议记录-编辑" } },
  { path: '/meet/edit/sign', name: 'meet-edit-sign', component: () => import('@/views/meet/edit/sign.vue'), meta: { title: "会议记录-签到" } },
  { path: '/meet/edit/pic', name: 'meet-edit-pic', component: () => import('@/views/meet/edit/pic.vue'), meta: { title: "会议记录-上传照片" } },
  { path: '/meet/edit/absent', name: 'meet-edit-absent', component: () => import('@/views/meet/edit/absent.vue'), meta: { title: "会议记录-请假" } },
  { path: '/meet/query', name: 'meet-query', component: () => import('@/views/meet/query/index.vue'), meta: { title: "会议记录" } },
  { path: '/meet/query/detail', name: 'meet-query-detail', component: () => import('@/views/meet/query/detail.vue'), meta: { title: "会议详情" } },
  { path: '/meet/query/edit', name: 'meet-query-edit', component: () => import("@/views/meet/query/edit.vue"), meta: { title: "会议编辑" } },

  { path: '/rent', name: 'rent', component: () => import('@/views/rent/index.vue'), meta: { title: "物品借用" } },
  { path: '/rent/new', name: 'rent-new', component: () => import('@/views/rent/new.vue'), meta: { title: "新物品借用申请单" } },
  { path: '/rent/lst', name: 'rent-lst', component: () => import('@/views/rent/lst.vue'), meta: { title: "借用记录" } },
  { path: '/rent/edit', name: 'rent-edit', component: () => import('@/views/rent/edit.vue'), meta: { title: "借用记录详情" } },

  { path: '/func', name: 'func-index', component: () => import('@/views/func/index.vue'), meta: { title: "功能室" } },
  { path: '/func/edit', name: 'func-edit', component: () => import('@/views/func/edit'), meta: { title: "功能室登记" } },
  { path: '/func/list', name: 'func-list', component: () => import('@/views/func/list'), meta: { title: "全部记录" } },
  { path: '/func/confirm', name: 'func-confirm', component: () => import('@/views/func/list/confirm'), meta: { title: "负责人确认" } },
  { path: '/func/info', name: 'func-info', component: () => import('@/views/func/list/info'), meta: { title: "活动记录" } },
  { path: '/func/report', name: 'func-report', component: () => import('@/views/func/report'), meta: { title: "统计报告" } },

  { path: '/visitor/index', name: "visitor-index", component: () => import('@/views/visitor/index'), meta: { title: '访问' } },
  { path: '/visitor/edit', name: 'visitor-edit', component: () => import('@/views/visitor/edit'), meta: { title: '访问', notLogin: true } },
  { path: '/visitor/new', name: 'visitor-new', component: () => import('@/views/visitor/new'), meta: { title: '访问', notLogin: true } },

  { path: '/check', name: 'check', component: () => import('@/views/check/new'), meta: { title: '考核记录' } },
  { path: '/check/index', name: 'check-index', component: () => import('@/views/check/index'), meta: { title: '考核记录' } },
  { path: '/check/edit', name: 'check-edit', component: () => import('@/views/check/edit/index'), meta: { title: '考核记录' } },
  { path: '/check/back', name: 'check-back', component: () => import('@/views/check/edit/back'), meta: { title: '考核记录' } },
  { path: '/check/list', name: 'check-list', component: () => import('@/views/check/list/index'), meta: { title: '考核记录' } },
  { path: '/check/mark', name: 'check-list-edit', component: () => import('@/views/check/list/edit'), meta: { title: '考核记录' } },
  { path: '/check/info', name: 'check-info', component: () => import('@/views/check/info/index'), meta: { title: '考核记录' } },

  { path: '/ins/index', name: 'ins-index', component: () => import('@/views/ins/index'), meta: { title: '检查巡检' } },
  { path: '/ins/rectify/record', name: 'ins-rectify-record', component: () => import('@/views/ins/rectify/record'), meta: { title: '整改记录' } },
  { path: '/ins/rectify/edit', name: 'ins-rectify-edit', component: () => import('@/views/ins/rectify/edit'), meta: { title: '巡检整改' } },
  { path: '/ins/rectify/detail', name: 'ins-rectify-edit', component: () => import('@/views/ins/rectify/detail'), meta: { title: '整改详情' } },
  { path: '/ins/new', name: 'ins-new', component: () => import('@/views/ins/new'), meta: { title: '新增巡检' } },
  { path: '/ins/list', name: 'ins-list', component: () => import('@/views/ins/list'), meta: { title: '巡检记录' } }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title;
  if (!to.meta.notLogin) {
    let token = localStorage.getItem('loginToken') || '';
    let timestamp = (new Date()).getTime();
    let expires = localStorage.getItem('tokenExpires') || 0;
    // console.log(token,expires,timestamp)
    if (token && expires > timestamp) {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  } else {
    next()
  }
})
export default router
