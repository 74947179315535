import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
// import axios from "axios";
import "babel-polyfill";

import whalePlugin from "./util/whale";
Vue.use(whalePlugin);

Vue.filter("datetime", function (val, f) {
  if (!val) return "";
  return moment(val).format(f);
});
Vue.prototype.$moment = moment

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts


import SignCanvas from "sign-canvas";
Vue.use(SignCanvas);

// axios.defaults.withCredentials = true;
// Vue.use(axios);
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

// axios.interceptors.request.use(
//   (config) => {
//     if (localStorage.getItem("loginToken")) {
//       config.headers.Authorization = localStorage.getItem("loginToken");
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
