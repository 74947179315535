/**
 * Created by whale on 2021/03/26.
 */
import axios from "axios";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
import Compressor from "compressorjs";
export default {
  install: function (Vue) {
    Vue.prototype.whale = {
      remote: {
        getResult: function (opt) {
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
          });
          axios
            .post(opt.url, JSON.stringify(opt.data || {}), {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then(function (resp) {
              Toast.clear();
              if (resp.data["Succeeded"]) {
                if (opt.completed != undefined)
                  opt.completed(resp.data["Data"]);
              } else {
                if (opt.failure != undefined) opt.failure(resp.data["Errors"]);
                else {
                  Toast.fail(resp.data["Errors"]);
                }
              }
              if (opt.finally != undefined) opt.finally();
            })
            .catch(function (error) {
              //  alert(error)
              Toast.clear();
              console.log("remote", error);
            });
        },
        getCollection: function (opt) {
          //Toast.loading({message:"加载中...",forbidClick:true,duration:0});
          axios
            .post(opt.url, JSON.stringify(opt.data || {}), {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then((resp) => {
              //Toast.clear()
              if (resp.data["Succeeded"]) {
                if (opt.completed != undefined)
                  opt.completed(resp.data.Data.DATA, resp.data.Data.DATA.TOTAL);
              } else {
                if (opt.failure != undefined) opt.failure(resp.data.MESSAGE);
                else console.log("remote", resp.data["MESSAGE"]);
              }
              if (opt.finally != undefined) opt.finally();
            })
            .catch((e) => {
              console.log("fetch error", e);
              //Toast.clear()
            });
        },
        uploadSign: function (opt) {
          axios
            .request({
              url: opt.url,
              method: "post",
              data: opt.data,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then((resp) => {
              console.log(resp.data);
              opt.completed(resp.data);
            });
        },
      },
      image: {
        compress(opt) {
          if (opt.quality == undefined) opt.quality = 0.2;
          new Compressor(opt.file, {
            quality: opt.quality,
            convertSize: 1048576,
            success(ret) {
              opt.callback(ret);
            },
            error(err) {
              alert(err);
            },
          });
        },
        toB64(opt) {
          let reader = new FileReader();
          reader.readAsDataURL(opt.file);
          reader.onload = (ev) => {
            let arr = ev.target.result.split(",");
            opt.callback(arr[1]);
          };
        },
      },
      grade: {
        lst: [
          { value: "0", name: "小小班" },
          { value: "1", name: "小班" },
          { value: "2", name: "中班" },
          { value: "3", name: "大班" },
          { value: "4", name: "学前班" },
        ],
        getName: function (code) {
          if (code == null) return "";
          let ret = "";
          for (let g of this.lst) {
            if (code == g.value) {
              return g.name;
            }
          }
          return ret;
        },
      },
      weeks: [
        { name: "周日", value: "0" },
        { name: "周一", value: "1" },
        { name: "周二", value: "2" },
        { name: "周三", value: "3" },
        { name: "周四", value: "4" },
        { name: "周五", value: "5" },
        { name: "周六", value: "6" },
      ],
      wxsdk: {
        ready: false,
        init(cb) {
          Vue.prototype.whale.remote.getResult({
            url: "/api/Mobile/WMP/JSS/GetJsApiConfig",
            data: {
              URL: window.location.origin + window.location.pathname,
            },
            completed: function (rep) {
              console.log("ws", rep);
              wx.config({
                debug: false,
                appId: rep.DATA.APP_ID,
                timestamp: rep.DATA.TIME_STAMP,
                nonceStr: rep.DATA.NONCE,
                signature: rep.DATA.SIGNATURE,
                jsApiList: [
                  "chooseImage",
                  "getLocalImgData",
                  "uploadImage",
                  "closeWindow",
                  "scanQRCode",
                ],
              });
              wx.ready(function () {
                Vue.prototype.whale.wxsdk.ready = true;
                if (cb != undefined) cb();
              });
              wx.error(function (res) {
                console.log("E", res.errMsg);
                Toast.fail("微信接口调用失败：" + res.errMsg);
              });
            },
          });
        },
        scanCode(fn_completed) {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
              fn_completed(result);
            },
          });
        },
      },
      user: {
        get() {
          let ret;
          const u = window.sessionStorage.getItem("YOA_USER");
          if (u) ret = JSON.parse(u);
          return ret;
        },
        set(m) {
          // console.log("mm", m);

          window.sessionStorage.setItem("YOA_USER", JSON.stringify(m));
        },
        clear() {
          window.sessionStorage.removeItem("YOA_USER");
        },
        validate(m_url) {
          //let u=this.data;
          let u = this.get();
          // console.log("u", u);
          if (u.ROLE == "ADMIN") return true;
          if (u.REL_MENU == null) return false;
          for (let i = 0; i < u.REL_MENU.length; i++) {
            let it = u.REL_MENU[i];
            if (it.URL == m_url) return true;
          }
          return false;
        },
      },
    };
  },
};
